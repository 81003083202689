import store from '@/store';
import { ref } from '@vue/composition-api';
import { mdiFileExcelOutline, mdiFileImageOutline, mdiFileOutline } from '@mdi/js';
import Vue from 'vue';

export default function useChargebackFilesList() {
  const files = ref([]);
  const loading = ref(false);
  // fetch data
  const fetchVisaRdrFiles = () => {
    store
      .dispatch('chargebackFile/fetchVisaRdrFiles')
      .then(response => {
        files.value = response.data;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Files',
          text: 'Cannot fetch files',
        });
      });
  };
  // fetch data
  const fetchVisaOipFiles = () => {
    store
      .dispatch('chargebackFile/fetchVisaOipFiles')
      .then(response => {
        files.value = response.data;
        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Files',
          text: 'Cannot fetch files',
        });
      });
  };

  const resolveFileTypeVariant = fileType => {
    if (fileType === 'jpg' || fileType === 'png' || fileType === 'jpeg') {
      return mdiFileImageOutline;
    } else if (fileType === 'xml' || fileType === 'csv') {
      return mdiFileExcelOutline;
    } else {
      return mdiFileOutline;
    }
  };

  return {
    files,
    loading,
    fetchVisaRdrFiles,
    fetchVisaOipFiles,
    resolveFileTypeVariant,
  };
}
